<template>
  <v-container id="juego-container">
    <v-img src="/img/juego/juego-mobile-bkg.png" class="juego-m-header">
      <juego-sidebar class="hidden-sm-and-up"/>
    </v-img>
    <div class="juego-text">
      <p>{{ juego_text }}</p>
    </div>
    <v-layout class="juego-m-portadas">
      <v-img v-for="(img, i) in juego_portadas" :key="i" :src="img" class="juego-m-images"></v-img>
    </v-layout>
    <v-layout column class="juego-m-info">
      <v-flex class="juego-m-download">
        <a href="/apk/cubeton.apk" target="_blank">
          <v-img src="/img/juego/juego-link.png" class="juego-m-logo ma-auto"></v-img>
          <span class="title-font juego-m-download-text">DESCARGAR</span>
        </a>
      </v-flex>
      <v-flex class="juego-m-creditos">
        <span class="juego-m-creditos-title">CRÉDITOS</span>
        <v-spacer class="juego-m-creditos-spacer"></v-spacer>
        <span class="juego-m-autores font-italic">
          J.M. – Programación <br/> Yasmany Janeiro – Ilustración <br/> César Hernández – diseño de niveles <br/> Antonio Serrano Muñoz – diseño <br/> Gustavo Viera López – dirección
        </span>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import JuegoSidebar from '../../components/Juego/JuegoSidebar'
export default {
  name: "",
  components: {
    'juego-sidebar': JuegoSidebar
  },
  data: () => ({
    juego_text: "La Cloaca Games es el nombre bajo el cual un grupo de jóvenes de diferentes formaciones se unieron motivados por desarrollar un videojuego sobre reguetón para cubanas y cubanos. El Cubetón, todavía en su versión de prueba, recopila canciones de reguetón y te propone un reto con ellas. Te adelantamos que no está fácil, pero descárgale.",
    juego_portadas: [
        '/img/juego/juego-portadas/portada-1.JPG',
        '/img/juego/juego-portadas/portada-2.JPG',
        '/img/juego/juego-portadas/portada-3.JPG',
        '/img/juego/juego-portadas/portada-4.JPG',
        '/img/juego/juego-portadas/portada-5.JPG',
    ]
  })
}
</script>
<style lang="scss" scoped>
#juego-container {
  margin: 0;
  padding: 0;
}

.juego-m-header {
  max-width: 100%;
  height: 100vh;
  // margin-top: 64px;
}

.juego-text {
  background-color: #676aaf;
  color: white;
  padding: 12vw;
  margin-top: -2px;
}

.juego-m-portadas {
  height: 100%;
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.juego-m-images {
  margin: 4vw 0 4vw 4vw;
  max-width: 100%;
  height: 100%;
  width: 50%;
}

.juego-m-download {
  align-items: center;
  -webkit-align-items: center;
  text-align: center;
  margin-top: 15vw;
}

.juego-m-logo {
  max-width: 130px;
  // margin-left: 32%;
}

.juego-m-download-text {
  color: #c160a2;
  letter-spacing: 6px;
}

.juego-m-creditos {
  font-family: Segoe;
  text-align : center;
  margin: 15vw 0;
}

.juego-m-creditos-title {
  font-weight: 600;
  letter-spacing: 6px;
}

.juego-m-autores {
  font-weight: bold;
  letter-spacing: 1px;
}

.juego-m-creditos-spacer {
  margin-top: 4vw;
}
</style>
