<template>
  <div class="juego-main-container" data-view>
    <v-layout column class="juego-container hidden-sm-and-down">
      <div class="juego-header">
        <v-layout row class="juego-portadas-container">
          <div class="juego-portada" v-for="(img, i) in juego_portadas" :key="i">
            <v-img class="juego-portada-img" :src="img"></v-img>
          </div>
        </v-layout>
      </div>
      <div class="juego-content-container">
        <v-layout row class="juego-content">
          <v-layout column class="juego-description">
            <v-img src="/img/juego/titulo-desktop.PNG" class="juego-titulo-desktop"></v-img>
            <div class="juego-text-container juego-description-text">
              <p class="juego-text" v-html="juego_text"></p>
            </div>
            <div class="creditos-container">
              <div class="juego-text-container">
                <h5 class="creditos-title text-uppercase title-font">créditos</h5>
              </div>
              <div class="juego-text-container juego-autores">
                <p
                  class="juego-autores text-capitalize"
                >J.M. – Programación / Yasmany Janeiro – Ilustración / César Hernández – diseño de niveles / Antonio Serrano Muñoz – diseño / Gustavo Viera López – dirección</p>
              </div>
            </div>
          </v-layout>
          <div class="juego-download-link-container">
            <v-layout column class="juego-download-link">
              <a href="/apk/cubeton.apk" class="juego-link" target="_blank">
                <v-img src="/img/juego/juego-link.png" class="juego-logo"></v-img>
                <div class="download">
                  <!-- <a href="#" class="juego-link"> -->
                  <span class="title-font text-uppercase download-text">descargar</span>
                  <!-- </a> -->
                </div>
              </a>
            </v-layout>
          </div>
        </v-layout>
      </div>
    </v-layout>
    <juego class="hidden-md-and-up" />
    <!-- <v-layout class="comentarios">
          <v-flex sm6>
            <ListaComentarios/>
          </v-flex>
          <ComentariosForm/>
        </v-layout>
    <ComentariosModal v-if="$store.getters.showModal"><ComentariosForm></ComentariosForm></ComentariosModal>-->
  </div>
</template>

<script>
// import ListaComentarios from '../../components/Comentarios/ListaComentarios';
// import ComentariosForm from '../../components/Comentarios/ComentariosForm';
// import ComentariosModal from '../../components/Comentarios/ComentariosModal';
import JuegoMobile from "./JuegoMobile";
// import { isMobile } from 'mobile-device-detect'

export default {
  name: "Juego",
  head: {
    title: {
      inner: "Flow y poder",
      separator: " - ",
      complement: "El Cubetón"
    },
    meta: [
      { p: "og:title", c: "Flow y poder - El Cubetón" },
      {
        p: "og:description",
        c: "Para que juegues a ritmo de reguetón cubano."
      },
      {
        n: "description",
        c: "Para que juegues a ritmo de reguetón cubano."
      },
      { p: "og:url", c: "https://reggaeton.eltoque.com/el-cubeton" },
      { p: "og:image", c: "https://reggaeton.eltoque.com/img/juego/portada.jpg" }
    ]
  },
  components: {
    // ListaComentarios,
    // ComentariosForm,
    // ComentariosModal,
    juego: JuegoMobile
  },
  data() {
    return {
      juego_portadas: [
        "/img/juego/juego-portadas/portada-1.JPG",
        "/img/juego/juego-portadas/portada-2.JPG",
        "/img/juego/juego-portadas/portada-3.JPG",
        "/img/juego/juego-portadas/portada-4.JPG",
        "/img/juego/juego-portadas/portada-5.JPG"
      ],
      juego_text:
        "Para que juegues a ritmo de reguetón cubano." +
        "</br></br>La Cloaca Games es el nombre bajo el cual un grupo de jóvenes de diferentes formaciones se unieron motivados por desarrollar un videojuego sobre reguetón para cubanas y cubanos. El Cubetón, todavía en su versión de prueba, recopila canciones de reguetón y te propone un reto con ellas. Te adelantamos que no está fácil, pero descárgale."
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit("setShowToolbarTitle", true);
      vm.$store.commit("setShowComponentsInFooter", true);
      vm.$store.commit("setViewCancionesBox", true);
      vm.$store.commit("setViewArtistasBox", true);
      vm.$store.commit("setViewLasBalasBox", true);
      vm.$store.commit("setViewJuegoBox", false);
      next();
    });
  }
};
</script>

<style scoped>
.juego-titulo-desktop {
  max-width: 200px;
}

.juego-main-container {
  width: 100%;
  overflow: hidden;
}

.juego-container {
  background-color: #676aaf;
}

.juego-link {
  text-decoration: none;
}

.juego-description {
  font-family: Segoe;
}

.juego-name {
  font-family: Andada;
}

.juego-portada {
  margin-left: 2vw;
}

.juego-portadas-container,
.juego-download-link,
.download {
  position: relative;
}

.download-text {
  color: #c160a2;
  letter-spacing: 6px;
}

.juego-text-container {
  color: black;
  letter-spacing: 1px;
  margin-top: 2vw;
}

.juego-autores {
  margin-top: 0;
  width: 32vw;
  line-height: 1.3;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  .juego-sidebar {
    display: none;
  }

  .juego-header {
    background: url("/img/juego/juego-header-bkg.png") no-repeat;
    background-size: contain;
    height: 78vh;
  }

  .juego-portadas-container {
    position: relative;
    top: 11.8vw;
    left: 14vw;
    z-index: 2;
  }

  .juego-portada {
    width: 12vw;
  }

  .juego-content-container {
    background-color: #676aaf;
    height: auto;
    margin-top: -1px;
  }

  .juego-content {
    background-color: white;
    position: relative;
    top: -3vw;
    z-index: 1;
    padding: 8vw 10vw 2vw;
    width: 80vw;
    left: 10vw;
  }

  .juego-description {
    max-width: 30vw;
  }

  .juego-name {
    font-size: 2vw;
    color: #c160a2;
  }

  .creditos-container {
    width: 30vw;
    line-height: 2;
    margin-top: -2vw;
  }

  .creditos-title {
    letter-spacing: 2px;
  }

  .juego-logo {
    max-width: 500px;
  }

  .download-text {
    font-size: 1.2vw;
  }

  .juego-download-link {
    left: 16vw;
    top: 5vw;
  }

  .comments-container-juego-desktop {
    position: relative;
    top: 4vw;
    left: 3.5vw;
    display: flex;
  }
}
</style>
