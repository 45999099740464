<template>
  <v-layout column class="juego-m-sidebar">
      <div class="juego-share-icon">
          <ESocialSharing class="juego-social-sharing"
            top right
            direction="left"
            transition="slide-x-transition"
            share_icon_color="white"/>
      </div>
      <v-spacer class="juego-sidebar-spacer"></v-spacer>
      <div @click="$store.commit('showModal', true)" class="comments-icon">
          <v-icon medium color="white">comment</v-icon>
      </div>
  </v-layout>
</template>
<script>
import ESocialSharing from '../SocialSharing/SocialSharing'
export default {
  name: "",
  components: {
      ESocialSharing
  },
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>
.juego-m-sidebar {
  width: 15vw;
  margin-left: auto;
  position: fixed;
  right: 0;
  z-index: 3;
  margin-top: 64px;
}

.comments-icon {
  background-color: #83cbcb;
  padding: 4vw 2vw 2vw;
  text-align: center;
}

.juego-share-icon {
  background-color: #c160a2
}

.juego-social-sharing {

}
</style>
